.title {
  margin-top: 10px !important;
  color: black;
  --ion-font-family: "poppins" !important;
  font-family: "poppins" !important;
  font-weight: 300;
  text-align: start;
  font-size: 40px;
  @media only screen and (max-width: 786px) {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 0 16px 0 !important;
  color: black;
}

.input-icon {
  color: var(--ion-color-secondary);
  width: 30px;
  height: 30px;
  @media only screen and (max-width: 786px) {
    width: 25px;
    height: 25px;
  }
}

.small-icon {
  width: 20px !important;
  height: 20px !important;
}

.date-input {
  --padding-end: 0 !important;
}

.outline-button {
  --border-width: 2px !important;
  --color: var(--ion-color-primary) !important;
  --background: transparent !important;
}

.outline-button-inverted {
  --border-width: 2px !important;
  --color: white !important;
  --background: transparent !important;
}

.damage-amount,
.damage-amount ion-input {
  font-size: 50px;
}

.slide {
  width: 100%;
  overflow-y: scroll !important;
  height: 100%;
}

.spacer {
  width: 100%;
}

.basic {
  background: var(--bs-light);
  height: 25px;
}

.confirmation {
  height: var(--height-confirm-button);
}

.h-400 {
  max-height: 400px;
  overflow-y: scroll;
}

swiper {
  max-width: 100vw !important;
}

/*------------------------------
          ion-elements
------------------------------*/

ion-content {
  --background: white;
}

.textarea,
textarea,
ion-textarea {
  --height: 100% !important;
  height: 100% !important;
  @media only screen and (max-width: 1199px) {
  }
  @media only screen and (min-width: 1200px) {
  }
  .halfsize {
    height: 25vh !important;
    min-height: auto !important;
  }
}

ion-textarea {
  overflow: auto;
}

ion-datetime {
  --background: var(--ion-color-primary);
  --width: 250px;
}

ion-button,
.btn-file-upload,
.btn-custom-bordered {
  --border-radius: 10px;
  border-radius: 10px;
  min-width: 120px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  --ion-font-family: "lato" !important;
  font-family: "lato" !important;
  @media only screen and (max-width: 767px) {
    height: 40px;
    font-size: 14px;
  }
}

.btn-file-upload,
.btn-custom-bordered {
  border: $primary 2px solid;
  color: $primary;
  border-radius: 10px;

  &:hover {
    background-color: #f7f8f8;
    border-color: $primary;
    color: $primary;
  }
}

ion-button[disabled] {
  --background: #e2efef !important;
  color: var(--ion-color-primary-tint) !important;
}

ion-button[disabled],
.native-input[disabled] {
  opacity: 1 !important;
}

ion-label {
  font-size: clamp(17px, 3vw, 23px);
  --ion-font-family: "lato" !important;
  font-family: "lato" !important;
  font-weight: 300 !important;
  padding-right: 1.5rem;
  text-align: left !important;
}

ion-label,
ion-input {
  color: black;
}

ion-input[disabled] {
  color: black !important;
}

ion-checkbox {
  --border-color: var(--ion-color-tertiary) !important;
  border-color: var(--ion-color-tertiary) !important;
  margin-right: 10px;
  --border-radius: 8px !important;
  --background: var(--ion-color-tertiary);
  pointer-events: none;
  width: 25px;
  height: 25px;
}

.checkbox-checked {
  --checkmark-width: 3;
}

ion-textarea {
  margin-top: 16px;
  border: 2px solid lightgray;
  border-radius: 10px;
  --padding-start: 20px !important;
  --padding-top: 20px !important;
  --padding-end: 20px !important;
  --padding-bottom: 20px !important;
  color: #797979 !important;
}

ion-card {
  border-radius: 10px;
}

.text-right {
  text-align: right;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mb-desktop {
  @media only screen and (min-width: 767px) {
    margin-bottom: 50px !important;
  }
}

.button-desktop {
  @media only screen and (min-width: 767px) {
    font-size: 25px;
    height: 55px;
    min-width: 220px;
  }
}

.back-button-desktop {
  @media only screen and (min-width: 767px) {
    height: 60px;
    font-size: 25px;
    margin-bottom: 100px !important;
    margin-right: 30px;
  }
}

.button-summary-desktop {
  @media only screen and (min-width: 767px) {
    font-size: 25px;
    height: 55px;
    min-width: 220px;
  }
}

.container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ios .swiper,
.md .swiper {
  ion-content {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}

.ios .swiper-slide,
.md .swiper-slide {
  overflow: scroll !important;
  height: 100% !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.slide {
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.w-1-3 {
  width: 30%;
}

.ml-auto {
  margin-left: auto;
}

.damage-container-desktop {
  @media only screen and (min-width: 800px) {
    background: #305951;
    color: white;
    border-radius: 10px;
    width: 600px;
    padding-bottom: 25px !important;
    padding-top: 25px !important;
    margin-top: 65px;
  }

  ion-label {
    color: black;
    @media (min-width: 567px) {
      color: white !important;
    }
  }
}

.pointer {
  @media only screen and (min-width: 800px) {
    height: 40px;
    width: 40px;
    background: #305951;
    margin: 0 auto;
    transform: rotate(45deg);
    border-radius: 4px;
    margin-top: -23px;
    left: 0;
  }
}

.pointer-summary {
  margin-left: 45px;
}

.height-desktop {
  height: 300px;
  @media only screen and (min-width: 768px) {
    height: 25vh !important;
  }
}

.damage-amount-summary {
  width: 100%;
  text-align: initial;
}

.damage-amount-value {
  font-size: 50px;
  text-align: left;
}

.summary {
  width: 100% !important;
  min-height: 70vh !important;
}

.full-width-green {
  width: 100%;
  background: #305951;
  color: white;
  padding-bottom: 44px !important;
  padding-top: 10px !important;
  margin-top: 65px;
  margin-bottom: 65px;
  border-radius: 8px;
  @media only screen and (max-width: 800px) {
    border-radius: 0 !important;
  }
}

.no-padding {
  padding: unset !important;
}

.mh-auto {
  min-height: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.toolbar {
  height: 90px;

  div {
    margin: 0 5px;
    height: 80px;
    width: 80px;
  }
}

.small-preview {
  margin: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  @media only screen and (min-width: 767px) {
    width: 200px;
    height: 200px;
    max-width: 200px !important;
    max-height: 200px !important;
  }
  border-radius: 10px;
  background: var(--ion-color-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-file {
  width: 200px;
  height: 200px;
  object-fit: cover !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;

  @media screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
  }
}

.active-file {
  max-height: 90px;
  @media only screen and (min-width: 767px) {
    max-height: 220px;
  }
  .small-file {
    opacity: 1;
  }

  .active-indicator {
    top: 100%;
    width: 45px;
    height: 10px;
    border-radius: 10px;
    left: calc((80px - 45px) / 2);
    @media only screen and (min-width: 767px) {
      left: 72.5px;
      bottom: -15px;
    }
  }
}

.file-desc-info {
  width: 90px;
  @media only screen and (min-width: 767px) {
    width: 200px;
  }
}

.active-desktop {
  height: 140px;
  max-width: 80px;
  @media only screen and (min-width: 767px) {
    height: 240px;
    max-width: 200px !important;
    border-bottom: white 16px solid;
  }
}

.date-button,
.date-input {
  max-width: 350px;

  @media only screen and (min-width: 767px) {
    height: 55px;
    width: 259px;
    font-size: 17px;
  }
  img {
    @media only screen and (min-width: 767px) {
      width: 20px;
      height: 20px;
    }
  }
}

.add-button {
  @media only screen and (min-width: 767px) {
    position: absolute;
    top: -120px;
  }
}

#photo-swiper {
  @media only screen and (min-width: 767px) {
    padding-bottom: 80px;
  }
}

.ta-l {
  text-align: left;
}

// .swiper {
//   @media only screen and (min-width: 767px) {
//     overflow: visible;
//   }
// }

.pdf-icon {
  filter: invert(31%) sepia(16%) saturate(1061%) hue-rotate(118deg)
    brightness(88%) contrast(85%);
  width: 80px;
  height: 80px;
}

.ios .pdf-icon {
  width: 30px;
  height: 30px;
}

.full-width-green {
  .mat-form-field-underline {
    background-color: white;
  }

  .mat-focused {
    .mat-form-field-ripple {
      background-color: $secondary;
    }
  }

  .mat-hint {
    color: white;
  }
}
.header-wrapper {
  height: auto;
  @media only screen and (min-width: 800px) {
    height: 55px;
  }
}

.textarea-wrapper {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.file-icon {
  top: 0;
  right: 0;
  position: absolute !important;
}

.file-pdf {
  width: 133px !important;
  height: auto !important;
  object-fit: cover !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  opacity: 0.5;
  margin: auto !important;

  .text-primary {
    width: 139%;
    font-size: 8px;
    margin-top: 7px;
  }
}

.ios .file-pdf {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover !important;
  .text-primary {
    width: 139%;
    font-size: 8px;
    margin-top: 0;
  }
}

.btn-custom-bordered {
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.safe-area-inset-top {
  --padding-top: env(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.safe-area-inset-bottom {
  --padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.mobile-spacer {
  height: 30px;
  @media only screen and (min-width: 800px) {
    display: none !important;
  }
}

.selection-start-button {
  height: 69px;
  width: 369px;
  font-size: 24px;
  font-weight: 400;
}

.ios .selection-start-button {
  height: 60px;
  width: 250px;
  font-size: 20px;
  font-weight: 400;
}

.w-25 {
  width: 40% !important;
  min-width: 110px !important;
}

app-text-input {
  pointer-events: none;

  .error {
    text-align: left;
    max-width: 95%;
    pointer-events: all !important;
  }
  ion-input,
  ion-textarea,
  textarea {
    pointer-events: all !important;
  }
}

app-tag-list {
  ion-input {
    border-bottom: 1.5px solid white !important;
  }
  app-text-input {
    .border-bottom {
      border-bottom: none !important;
    }
  }
  .has-focus {
    border-bottom: 1.5px solid var(--ion-color-secondary) !important;
  }
}

.normalize-icon {
  @media only screen and (max-width: 800px) {
    padding-left: 11px;
    padding-right: 11px;
  }
}
